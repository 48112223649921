import React, { useEffect, useRef, useState, Suspense } from "react"
import styled from "styled-components"
import Typography from "@material-ui/core/Typography"
import {
  EmailShareButton,
  TelegramShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
} from "react-share"

const windowGlobal = typeof window !== "undefined" && window

const shareConfig = {
  title: "Into DeFi - Recursos y consultas sobre el movimiento DeFi",
  url: "https://intodefi.com",
}

const SuccessBox = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & #confetti {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 300px;
  }
`
const ConfettiBox = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ShareBox = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 60px;
  & .react-share__ShareButton {
    margin: 10px 6px;
  }
`
const scrollToRef = ref =>
  setTimeout(
    () =>
      window.scrollTo(
        0,
        windowGlobal.pageYOffset + ref.current.getBoundingClientRect().top
      ),
    300
  )

const Confetti = React.lazy(() => import("react-confetti-canvas"))

const Success = () => {
  const refScroll = useRef(null)

  useEffect(() => {
    if (windowGlobal.innerWidth < 1280) {
      scrollToRef(refScroll)
    }
  }, [])

  return (
    <SuccessBox>
      <div>
        <ConfettiBox ref={refScroll}>
          <Suspense fallback={<></>}>
            <Confetti />}
          </Suspense>
          <Typography
            gutterBottom
            variant="h2"
            component="h2"
            style={{ color: "#0004ff", fontWeight: "bold" }}
          >
            ¡Bienvenido a Into DeFi!
          </Typography>
        </ConfettiBox>

        <ShareBox>
          <Typography
            gutterBottom
            variant="h4"
            component="h3"
            style={{ color: "black" }}
          >
            Comparte ahora en redes sociales
          </Typography>
          <EmailShareButton {...shareConfig}>
            <EmailIcon size={64} round={true} />
          </EmailShareButton>
          <TelegramShareButton {...shareConfig}>
            <TelegramIcon size={64} round={true} />
          </TelegramShareButton>
          <TwitterShareButton
            {...shareConfig}
            hashtags={["#defi", "#ethereum"]}
          >
            <TwitterIcon size={64} round={true} />
          </TwitterShareButton>
          <LinkedinShareButton {...shareConfig}>
            <LinkedinIcon size={64} round={true} />
          </LinkedinShareButton>
        </ShareBox>
      </div>
    </SuccessBox>
  )
}

export default Success
