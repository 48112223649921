import React from "react"
import styled from "styled-components"
import Typography from "@material-ui/core/Typography"

const WrappedTypography = ({ maxWidth, ...props }) => {
  return <Typography {...props} />
}

const Text = styled(WrappedTypography)`
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : null)}
`
export default Text
