import React from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Avatar from "@material-ui/core/Avatar"
import Link from "@material-ui/core/Link"

import { useStaticQuery, graphql } from "gatsby"

const listBlogMetadata = graphql`
  query {
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }, limit: 1000) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            img
            author
            author_img
          }
        }
      }
    }
  }
`

const BlogCard = styled(Card)`
  &&& {
    color: rgba(0, 0, 0, 0.84);
    display: block;
    height: 394px;
    width: 100%;
    &:hover {
      background: rgba(0, 0, 0, 0.01);
    }
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.1);
    text-transform: none;
    text-decoration: none;
  }
`

const WrappedMedia = ({ backgroundColor, size, ...props }) => (
  <CardMedia {...props} />
)

const Media = styled(WrappedMedia)`
  height: 240px;
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "none"};
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${({ size }) => (size ? size : "cover")};
`

const AuthorAvatarSmall = styled(Avatar)`
  width: 40px;
  height: 40px;
  margin: 0px 10px 0px 0px;
`

const Blogs = () => {
  const {
    allMdx: { edges },
  } = useStaticQuery(listBlogMetadata)
  const blogs = edges.map(({ node: { frontmatter } }) => frontmatter)
  return (
    <Box mt={5} mb={3}>
      <Typography gutterBottom variant="h2" align="center">
        Blog
      </Typography>
      <Typography paragraph align="center">
        Aprende sobre DeFi y blockchain en el blog de "Into Defi".
      </Typography>

      <Grid container spacing={2} justify="center">
        {blogs.map(({ path, title, author, img, author_img, date }) => (
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={title}>
            <BlogCard component={Link} href={path}>
              <Media image={img} title={title} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {title}
                </Typography>
                <Box display="flex" alignItems="center">
                  <AuthorAvatarSmall alt={author} src={author_img} />
                  <div>
                    <Typography variant="subtitle1">{date}</Typography>
                    <Typography variant="subtitle1">{author}</Typography>
                  </div>
                </Box>
              </CardContent>
            </BlogCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Blogs
