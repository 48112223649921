import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from "gatsby"


const EurInDefi = (props) => {
  const [counter, setCounter] = useState(10000000);
  

  const {defiData: {eurInDefi: totalEur}} = useStaticQuery(graphql`
    query {
      defiData {
        eurInDefi
      }
    }
  `)

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter => {
        const icc = 10000000 + Math.floor(Math.random() * 90000) + 10000;
        if ((counter + icc) < totalEur) {
          return counter + icc;
        }
        clearInterval(interval);
        return totalEur;
      });
    }, 20);

    return () => {
      clearInterval(interval);
    };
  }, [totalEur])

  const millions = Number((counter / 1000000)).toLocaleString('es-ES', { maximumFractionDigits: 1 })

  return <span {...props}>{millions} Millones de Euros</span>;
};

export default EurInDefi;