import React, { useEffect } from "react"

const EmbedTypeform = ({ className }) => {
  useEffect(() => {
    var qs,
      js,
      q,
      s,
      d = document,
      gi = d.getElementById,
      ce = d.createElement,
      gt = d.getElementsByTagName,
      id = "typef_orm",
      b = "https://embed.typeform.com/"
    if (!gi.call(d, id)) {
      js = ce.call(d, "script")
      js.id = id
      js.src = b + "embed.js"
      q = gt.call(d, "script")[0]
      q.parentNode.insertBefore(js, q)
    }
  }, [])
  return (
    <div
      className={`typeform-widget ${className}`}
      data-url="https://david162906.typeform.com/to/n2rAbX"
      data-transparency="50"
      data-hide-footer="true"
      style={{
        maxWidth: "680px",
        width: "100%",
        maxHeight: "540px",
        height: "540px",
        margin: "0 auto",
      }}
    />
  )
}

export default EmbedTypeform
