import styled, { keyframes } from 'styled-components';

export const RainbowKeyframe = keyframes`
    100%,0%{
        background-color: rgb(255,0,0);
    }
    8%{
        background-color: rgb(255,127,0);
    }
    16%{
        background-color: rgb(255,255,0);
    }
    25%{
        background-color: rgb(127,255,0);
    }
    33%{
        background-color: rgb(0,255,0);
    }
    41%{
        background-color: rgb(0,255,127);
    }
    50%{
        background-color: rgb(0,255,255);
    }
    58%{
        background-color: rgb(0,127,255);
    }
    66%{
        background-color: rgb(0,0,255);
    }
    75%{
        background-color: rgb(127,0,255);
    }
    83%{
        background-color: rgb(255,0,255);
    }
    91%{
        background-color: rgb(255,0,127);
    }
}
`
export const RainbowLine = styled.div`
    height: 3px;
    width: 100%;
    animation: ${RainbowKeyframe} 2.5s linear;
    animation-iteration-count: infinite;
`

export default RainbowLine;