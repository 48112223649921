import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import withLocation from "../utils/withLocation"
import {
  EmailShareButton,
  TelegramShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
} from "react-share"

const windowGlobal = typeof window !== "undefined" && window

const config = {
  angle: 90,
  spread: 45,
  startVelocity: 45,
  elementCount: 50,
  dragFriction: 0.1,
  duration: 3000,
  stagger: "5",
  width: "9px",
  height: "36px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
}

const ShareBox = styled.div`
  text-align: center;
  margin: 0px 0px 30px 0px;
  & .react-share__ShareButton {
    margin: 0px 6px;
  }
`
const NewsletterHolder = styled.div`
  margin: 30px 0px;

  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  text-align: center;
  @media (min-width: 600px) {
    padding: 0px 18px;
  }
`

const NewsButton = styled(Button)`
  &&& {
    color: white;
    background: rgba(250, 177, 31, 1);
    font-size: 18px;
    padding: 10px 30px;
    width: 100%;
  }
  &&&:hover {
    background: rgba(250, 177, 31, 0.9);
  }
`
const InputText = styled(TextField)``

const FormBox = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  & .email-octopus-form-wrapper {
    width: 100%;
    max-width: 680px;
  }

  & ${InputText} {
    max-width: 100%;
    width: 100%;
  }
`

const Newsletter = ({ location, title }) => {
  const { pathname } = location
  const [buttonId, setButtonId] = useState(null)
  const shareConfig = {
    title: title
      ? title
      : "Into DeFi - Recursos y consultas sobre el movimiento DeFi",
    url: "https://intodefi.com" + pathname,
  }
  useEffect(() => {
    const callbackName = "onRecaptchaSuccess"

    const randomSuffix = Math.random()
      .toString(36)
      .substr(2, 5)
    const captchaId = "email-octopus-form-submit-" + randomSuffix

    windowGlobal[callbackName] = function() {
      windowGlobal.$(".recaptcha-error-message").remove()
      windowGlobal.$(".recaptcha-error").removeClass()
      windowGlobal.emailOctopus.submit(windowGlobal.$("#email-octopus-1"))
    }
    setButtonId(captchaId)
  }, [])

  const onCaptcha = function(event) {
    event.stopPropagation()
    event.preventDefault()
    windowGlobal.grecaptcha.execute()
    return false
  }

  const onSubmitCallback = function(event) {
    event.stopPropagation()
    event.preventDefault()
    return false
  }

  return (
    <>
      <NewsletterHolder>
        <link
          rel="stylesheet"
          href="https://emailoctopus.com/bundles/emailoctopuslist/css/formEmbed.css"
        />

        <FormBox>
          <div className="email-octopus-form-wrapper">
            <p className="email-octopus-success-message"></p>
            <p className="email-octopus-error-message"></p>
            <form
              method="post"
              id="email-octopus-1"
              action="https://emailoctopus.com/lists/0d4860f0-564a-11ea-a3d0-06b4694bee2a/members/embedded/1.3s/add"
              className="email-octopus-form"
              data-sitekey="6LdYsmsUAAAAAPXVTt-ovRsPIJ_IVhvYBBhGvRV6"
              onSubmit={onSubmitCallback}
            >
              <InputText
                id="field_1"
                name="field_1"
                label="Nombre"
                type="text"
                placeholder=""
                variant="outlined"
              />
              <br />
              <br />
              <InputText
                id="field_0"
                name="field_0"
                type="email"
                label="Email"
                variant="outlined"
                placeholder=""
              />

              <div className="email-octopus-form-row-consent">
                <input type="checkbox" id="consent" name="consent" />
                <label htmlFor="consent">
                  He leído y acepto la{" "}
                  <a
                    href="https://intodefi.com/politica-de-privacidad"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    política de privacidad
                  </a>
                </label>
              </div>

              <div className="email-octopus-form-row-hp" aria-hidden="true">
                <input
                  type="text"
                  name="hp0d4860f0-564a-11ea-a3d0-06b4694bee2a"
                  tabIndex="-1"
                  autoComplete="nope"
                />
              </div>

              <div className="email-octopus-form-row-subscribe">
                <input
                  type="hidden"
                  name="successRedirectUrl"
                  value="/?success=true"
                />
                <NewsButton
                  id={buttonId}
                  type="button"
                  variant="contained"
                  onClick={onCaptcha}
                  color="primary"
                >
                  ¡Si quiero!
                </NewsButton>
              </div>
              {buttonId && (
                <div
                  className="g-recaptcha"
                  data-theme="light"
                  data-size="invisible"
                  data-type="image"
                  data-sitekey="6LdYsmsUAAAAAPXVTt-ovRsPIJ_IVhvYBBhGvRV6"
                  data-callback="onRecaptchaSuccess"
                  data-bind={buttonId}
                />
              )}
            </form>
          </div>
        </FormBox>
      </NewsletterHolder>
      <ShareBox>
        <Typography
          gutterBottom
          variant="h5"
          component="h3"
          style={{ color: "black" }}
        >
          Comparte en redes sociales
        </Typography>
        <EmailShareButton {...shareConfig}>
          <EmailIcon size={42} round={true} />
        </EmailShareButton>
        <TelegramShareButton {...shareConfig}>
          <TelegramIcon size={42} round={true} />
        </TelegramShareButton>
        <TwitterShareButton {...shareConfig}>
          <TwitterIcon size={42} round={true} />
        </TwitterShareButton>
        <LinkedinShareButton {...shareConfig}>
          <LinkedinIcon size={42} round={true} />
        </LinkedinShareButton>
      </ShareBox>
    </>
  )
}

export default withLocation(Newsletter)
