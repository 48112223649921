import React from "react"
import styled from "styled-components"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"
import { makeStyles } from "@material-ui/core/styles"

import Newsletter from "../components/newsletter"
import Text from "../components/text"
import Resources from "../components/resources"
import LendingRates from "../components/lending_rates"
import Typeform from "../components/typeform"
import Footer from "../components/layouts/footer"
import Blogs from "../components/blogs"
import SEO from "../components/seo"
import Success from "../components/success"
import withLocation from "../utils/withLocation"

const RootGrid = styled(Grid)`
  & > div:first-child {
    min-height: 100vh;
  }
`

const NewsTitle = styled(Text)`
  position: relative;
  margin: 2.2em auto 20px;
  z-index: 100;
  @media screen and (max-width: 500px) {
    margin: 1em auto 20px;
  }
`

const FormTitle = styled(Text)`
  position: relative;
  margin: 1.2em auto 30px;
  z-index: 100;
  @media screen and (max-width: 500px) {
    margin: 1em auto -120px;
  }
`
const Description = styled(Text)`
  margin: 0 auto;
  font-size: 1.2rem;
  padding: 0px 12px;
`
const DescriptionForm = styled(Text)`
  margin: 0 auto;
  font-size: 1.2rem;
  padding: 0px 12px;
  @media screen and (max-width: 500px) {
    display: none;
  }
`
const useStyles = makeStyles(theme => ({
  blue: {
    color: "white",
    background: "rgb(0, 0, 0)",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}))

const IndexPage = ({ search }) => {
  const success = !!search.success
  const classes = useStyles()
  const HomeNewsletter = () => (
    <>
      <NewsTitle maxWidth="600px" align="center" variant="h2" paragraph>
        ¿Quieres aprender más sobre DeFi?
      </NewsTitle>
      <Description maxWidth="680px" align="center" variant="subtitle1">
        Hay una nueva revolución financiera ocurriendo ahora mismo donde todo
        gira alrededor de la Blockchain. Protocolos financieros. Ethereum.
        Inversión alternativa. Monedas estables. ¿Te lo vas a perder?
      </Description>
      <Newsletter />{" "}
    </>
  )
  return (
    <>
      <SEO
        title="Into DeFi - Recursos y consultas sobre DeFi"
        description="Consulta información y obtén recursos relacionados con el movimiento DeFi en español."
      />
      <RootGrid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={12} sm={12} md={12} lg={7} className={classes.blue}>
          <LendingRates />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={5}
          style={{ position: "relative" }}
        >
          {success && <Success />}
          {!success && <HomeNewsletter />}
        </Grid>
      </RootGrid>
      <Divider />
      <Container>
        <Blogs />
        <Resources />
        <FormTitle
          maxWidth="600px"
          align="center"
          variant="h2"
          paragraph
          className={classes.consultNow}
        >
          Consulta ahora
        </FormTitle>
        <DescriptionForm maxWidth="680px" align="center" variant="subtitle1">
          ¿Tienes dudas sobre cómo participar en la economía DeFi? ¿Necesitas
          formación? Puedes realizar consultas relacionadas con DeFi o
          Blockchain mediante este formulario y recibirás una respuesta en
          breves.
        </DescriptionForm>
        <Typeform className={classes.form} />
      </Container>
      <Footer />
    </>
  )
}

export default withLocation(IndexPage)
