import React from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import GridList from "@material-ui/core/GridList"
import GridListTile from "@material-ui/core/GridListTile"
import GridListTileBar from "@material-ui/core/GridListTileBar"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import CardActionArea from "@material-ui/core/CardActionArea"
import ListSubheader from "@material-ui/core/ListSubheader"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Link from "@material-ui/core/Link"

const tileData = [
  {
    img: "/resources/defi_pulse.svg",
    title: "Defi Pulse",
    url: "https://defipulse.com/",
    desc:
      "Estadísticas generales sobre DeFi: volumen actual, listado de productos DeFi, tabla de intereses de préstamos. ",
    background: "#2e1a4e",
  },
  {
    img: "/resources/defi_prime.svg",
    title: "Defi Prime",
    url: "https://defiprime.com",
    desc: "Listado de productos y estadísticas de retorno de préstamos DeFi.",
  },
  {
    img: "/resources/loan_scan.svg",
    title: "Loan Scan",
    url: "https://loanscan.io/",
    desc: "Listado de productos de préstamos DeFi.",
  },
]

const lendingData = [
  {
    img: "/resources/aave.svg",
    title: "Aave",
    desc:
      "Deposita tus criptomonedas para ganar intereses o pide un préstamo depositando tus criptomonedas como garantía. Flash loans.",
    url: "https://aave.com/",
    background: "#000",
    size: "300px 200px",
  },
  {
    img: "/resources/compound_logo.svg",
    title: "Compound",
    desc:
      "Préstamos con garantías mediante un fondo común. Presta o pide un préstamo con un 150% de colateral con diferentes monedas.",
    url: "https://compound.finance/",
    background: "#000",
    size: "300px 200px",
  },
  {
    img: "/resources/dydx_logo.svg",
    title: "dYdX",
    desc:
      "Casa de cambio descentralizada con cuenta de ahorro y con margin trading hasta 5x.",
    url: "https://dydx.exchange",
    background: "#000",
  },
  {
    img: "/resources/raise.svg",
    title: "Raise",
    desc:
      "Préstamos sin colateral para empresas, con altos intereses para inversores.",
    url: "https://raise.it/",
  },
  {
    img: "/resources/maker_dao.png",
    title: "Maker DAO",
    desc:
      "Obtén un préstamo en DAI con multi-colateral. Puedes obtener un 6 % de intereses anuales con la cuenta de ahorro de DAI.",
    url: "https://makerdao.com/",
  },
  {
    img: "/resources/dharma_logo.svg",
    title: "Dharma",
    desc:
      "Préstamos con colateral mediante Coinbase. Utilizan Compound como protocolo base.",
    url: "https://dharma.io/",
    size: "100px 100px",
  },
]

const GridListTileWrapper = ({ backgroundColor, ...props }) => (
  <GridListTile {...props} />
)

const GridListTileItem = styled(GridListTileWrapper)`
  & img {
    width: 90%;
    padding: 20px;
    margin: 0 auto;
  }
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "inherit"};
`
const LendingProduct = styled(Card)`
  &&& {
    display: block;
    max-width: 395px;
    width: 100%;
    color: rgba(0, 0, 0, 0.84);
    height: 270px;
    &:hover {
      background: rgba(0, 0, 0, 0.001);
    }
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.1);
    text-transform: none;
    text-decoration: none;
  }
`

const WrappedMedia = ({ backgroundColor, size, ...props }) => (
  <CardMedia {...props} />
)

const Media = styled(WrappedMedia)`
  height: 140px;
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "none"};
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${({ size }) => (size ? size : "contain")};
`

const Resources = () => (
  <Box mt={5} mb={3}>
    <Typography variant="h2" align="center">
      Recursos
    </Typography>
    <Typography paragraph align="center">
      Listado de productos y páginas web relacionadas con DeFi
    </Typography>

    <Box mt={6} mb={3}>
      <Typography variant="h4">Analíticas y estadísticas</Typography>
    </Box>
    <GridList cellHeight={190}>
      {tileData.map(tile => (
        <GridListTileItem
          backgroundColor={tile.background}
          component="a"
          key={tile.title}
          href={tile.url}
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <img src={tile.img} alt={tile.title} />
          <GridListTileBar title={tile.title} />
        </GridListTileItem>
      ))}
    </GridList>
    <Box mt={6} mb={3}>
      <Typography variant="h4" mt={2}>
        Productos de préstamos y cuentas de ahorro
      </Typography>
    </Box>
    <Grid container spacing={2}>
      {lendingData.map(tile => (
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={tile.title}>
          <LendingProduct
            component={Link}
            href={tile.url}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <Media
              backgroundColor={tile.background}
              size={tile.size}
              image={tile.img}
              title={tile.title}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {tile.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {tile.desc}
              </Typography>
            </CardContent>
          </LendingProduct>
        </Grid>
      ))}
    </Grid>
  </Box>
)

export default Resources
