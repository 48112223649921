import React, { useEffect } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import Box from "@material-ui/core/Box"
import Link from "@material-ui/core/Link"
import Text from "./text"
import Socials from "./socials"
import EurInDefi from "../components/EurInDefi"
import RainbowLine from "../components/rainbow"

const useStyles = makeStyles({
  table: {
    maxWidth: 550,
  },
})

const WhiteSocials = styled(Socials)`
  & .icon {
    color: white;
  }
`

const TablesBox = styled.div`
  background: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1.2em;
  & > * {
    margin: 0.6em;
    padding: 20px;
    max-width: 350px;
    width: 100%;
  }
`

const FlexTitle = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  & > img {
    width: 40px;
    margin-right: 10px;
  }
`
const Logo = styled(Text)`
  font-size: 8em;
  margin-top: 0.4em;
  font-weight: bold;
`
const Subtitle = styled(Text)`
  margin-top: 1em;
  font-weight: normal;
`

const CardsTitle = styled(Text)`
  margin: 0.8em auto 0.1em;
`

const LendingRatesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  & a:active,
  & a {
    color: white;
    text-decoration: underline;
  }
`

const LendingRates = () => {
  const {defiData: {lendingPools: tokens}} = useStaticQuery(graphql`
    query {
      defiData {
        lendingPools {
          token {
            name
          }
          rates {
            Compound {
              name
              lend {
                rate
              }
            }
            Maker {
              name
              lend {
                rate
              }
            }
            dYdX  {
              name
              lend {
                rate
              }
            }
          }
        }
      }
    }
  `)

  const classes = useStyles()

  const getTokenTable = ({ token, rates }) => {
    if (!token || !rates) {
      return null
    }
    const body = Object.values(rates).filter(x => !!x).map(({ name, lend: { rate } }) => {
      return (
        <TableRow key={name}>
          <TableCell component="th" scope="row">
            {name}
          </TableCell>
          <TableCell align="left">
            {Number(rate).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            %
          </TableCell>
        </TableRow>
      )
    })
    return (
      <TableContainer component={Paper} key={token.name}>
        <FlexTitle>
          <img src={`/coins/${token.name.toLowerCase()}.png`} />
          <h4>{token.name}</h4>
        </FlexTitle>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell size="small">Plataforma</TableCell>
              <TableCell align="left" size="small">
                Rentabilidad anual APR
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{body}</TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <LendingRatesContainer>
      <Logo variant="h1" align="center" weight="600">
        Into #DeFi
      </Logo>
      <RainbowLine />
      <Subtitle variant="h6" maxWidth="680px" align="center">
        Conoce el movimiento DeFi, una red de protocolos que utilizan la
        tecnología Blockchain para ofrecer crédito de manera rápida,
        transparente y abierto a cualquier individuo.
      </Subtitle>
      <CardsTitle variant="h3" align="center">
        Descubre la financiación colectiva
      </CardsTitle>
      <Text variant="body1" align="center">
        Más de{" "}
        <b>
          <EurInDefi />
        </b>{" "}
        están dentro de la red repartidos en los siguiente protocolos.
      </Text>
      <TablesBox>{tokens.map(getTokenTable)}</TablesBox>
      <p>
        Datos obtenidos desde la API de{" "}
        <Link href="https://defipulse.com" target="blank">
          Defi Pulse
        </Link>
        .
      </p>
      <WhiteSocials />
      <Box my={1}>
        <Text align="center">
          Made with ❤ by{" "}
          <Link
            href="https://etherscan.io/address/kartojal.eth"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            kartojal.eth
          </Link>
        </Text>
      </Box>
    </LendingRatesContainer>
  )
}

export default LendingRates
